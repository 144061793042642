// thead[class*="ant-table-thead"] th{
//   background-color:#000 !important;
//   color: white;
//   font-weight: bold;
//   border-color: #000;
//   text-align: center;
// }
tr:nth-child(odd){ 
  background: #f0f0f0;
}
tr:nth-child(even){
  background: #ffffff;
}
.sideNav_sider {
  overflow: auto;
  overflow-y: scroll !important;
}
// .ant-table-thead .ant-table-cell {
//   background-color: green;
//   color: red;
// }
@primary-color: #002EF3;@form-item-margin-bottom: 10px;@layout-header-background: #002EF3;@form-item-trailing-colon: false;@font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@heading-color: #666;