#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: black;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  margin-left: 300px !important;
}

.site-layout-small .site-layout-background {
  background: #fff;
  margin-left: 80px !important;
}

.non-collapsed-layout{
  float: right;
  margin-right: 17em !important;
  margin-top: 14px !important;
}

.collapsed-layout{
  float: right;
  margin-right: 5em !important;
  margin-top: 14px !important;
}

.user-profile{
  float: right;
}

.ant-menu-item-selected {
  background-color: #002EF3 !important;
  color: white !important;
}

.ant-menu-item-selected::after {
  opacity: 1 !important;
  border-right: 3px solid #ACD0EA !important;
}

.ant-menu-item,
.ant-menu-submenu {
  transition: 0s !important;
  font-size: 18px;
}

.ant-menu-submenu {
  color: black !important;
  font-size: 18px !important;
}
.ant-menu-submenu:hover {
  color: black !important;
}
.ant-menu-item:hover {
  background-color: #002EF3 !important;
  color: white !important;
}

.ant-menu-item .anticon {
  font-size: 18px !important;
}

.ant-menu-submenu .anticon {
  font-size: 18px !important;
}

.ant-menu-submenu-title:hover {
  color: black !important;
}

.label {
  margin-left: 20px !important;
  transition: 0s !important;
}

/* Custom Styling */

.sideNav_sider {
  height: 100vh !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  z-index: 10 !important;
  position: fixed !important;
}

.sideNav_header {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  z-index: 30 !important;
  position: fixed !important;
  width: 100% !important;
  padding: 0 !important;
}

.sideNav_button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 20px !important;
}

.large_text {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.menuItem_height {
  height: 3.5rem !important;
}

.ant-menu-title-content{
  // font-size: small !important;
}

.logo {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 7rem !important;
}

.content {
  margin-top: 65px;
  padding: 0.75rem;
}

.disableTextSelect {
  user-select: none;
}

.site-layout .ant-layout-footer {
  margin-left: 300px;
}

.site-layout-small .ant-layout-footer {
  margin-left: 80px;
}

.h-90{
  height: 93vh !important;
}

.w-90{
  width: 90%;
}

.logo-container {
  padding-bottom: 50px;
  padding-top: 50px;
}

.site-layout-content {
  min-height: 200px !important;
}

.left-col-login {
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-position: center;
}

.mr-2{
  margin-right: 2rem;
}

.h1{
  font-size: 1em;
  font-weight: bolder;
}
.fz-3em{
  font-size: 3em;
  font-weight: bolder;
}
.mv-2{
  margin: 0px 2px 2px 0px !important;
}
.mv-4{
  margin: 0px 4px 4px 0px !important;
}
.mv-6{
  margin: 0px 8px 8px 0px !important;
}
.ml-24{
  margin-left: 24px !important;
}
.table-header-space-between{ 
  display: "flex" !important;
  justify-content: "space-between" !important;
   align-items: "center" !important;
  }
@primary-color: #002EF3;@form-item-margin-bottom: 10px;@layout-header-background: #002EF3;@form-item-trailing-colon: false;@font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@heading-color: #666;